<!--
 * @Author: your name
 * @Date: 2020-04-09 16:09:28
 * @LastEditTime: 2020-11-11 17:17:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/index.vue
-->
<template>
  <div style="display: flex;flex-direction: column;height:100%">
    <el-scrollbar
      ref="myScrollbar"
      :native="false"
      wrap-style
      wrap-class
      view-class
      view-style
      :noresize="false"
      tag="section"
      class="main-special"
    >
      <main>
        <keep-alive>
          <router-view />
        </keep-alive>
      </main>
    </el-scrollbar>
  </div>
</template>
<script>
const catchList = [];

export default {
  name: 'Index',
  data() {
    return {};
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    catchPage() {
      return catchList.some(item => {
        return this.$route.fullPath.indexOf(item) !== -1;
      });
    }
  }
  // watch: {
  //   // 监听路由变化，定位至页面顶部
  //   $route(to, from) {
  //     this.$refs.myScrollbar.wrap.scrollTop = 0;
  //   }
  // }
};
</script>
<style lang="scss" scope>
.main-special {
  height: auto;
  // 修改bg-color
  // background: #fff!important;
  .el-scrollbar__wrap {
    &:nth-of-type(1) {
      overflow-x: hidden !important;
    }
  }
}
</style>
